import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col, FormControl } from "react-bootstrap";

import { Button, Section, Box, Text, Input, MainTitle, Select } from "../../components/Core";
import { Formik, Form } from 'formik';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PageWrapper from "../../components/PageWrapper";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";
import * as Yup from "yup";
import { Modal } from 'react-bootstrap'

import APINO from './../../api/api-no';
import API from './../../api/api';
import AsyncSelect from "react-select/async";

const FormStyled = styled.form`
    .css-15jcznz-control,
    .css-1wqlcqr-control { 
        .css-1wa3eu0-placeholder {
            color: #000;
            left: 10px;
        }
    }

    .reactSelect:not(.check-availability-select) .css-1wqlcqr-control {
        border-color: #000 !important;
        outline: 1px solid #000 !important;
    }

    .reactSelect:not(.check-availability-select) .css-ik9khr-control {
        border-color: #000 !important;
        outline: 1px solid #000 !important;
    }
    
    .css-1rz98ew-control, .css-ik9khr-control {
        padding: 4px 8px;
        border: 1px solid grey;
        .css-1wa3eu0-placeholder { 
            left: 8px;
            font-size: 1rem;
            font-weight: 300;
            color: #000;
        }
    }
`;

const submitSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('This field is required'),
    lastName: Yup.string()
        .required('This field is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('This field is required'),
    messageText: Yup.string()
        .required('This field is required'),
});

function validateFeedback(feedback) {
    if (feedback) {
        return <FormControl.Feedback
            type="invalid"
            className="pl-1">{feedback}
        </FormControl.Feedback>
    } else {
        return <></>
    }
}

const Contact = () => {

    const [typesOfQuery,] = useState([
        { value: 'GENERAL_FEEDBACK', label: 'General build enquiry' },
        { value: 'ENGINEER_FEEDBACK', label: 'Feedback on an engineer visit' },
        { value: 'NETOMNIA_FEEDBACK', label: 'Feedback for Netomnia' },
        { value: 'BROADBAND_PROBLEM', label: 'Problem with my broadband' }
    ]);

    const [address, setAddress] = useState(null);

    const [queryType, setQueryType] = useState(null);

    const data = useStaticQuery(graphql`
        query strapiContactQuery {
            strapiContactPage {
                title
                subtitle_1
                subtitle_2
                enquiry_notice
                title_2
                text_1
                text_2
                text_3
            }
            strapiPageHeadings(slug: {eq: "contact"}) {
                h1
            }
        }
    `);

    const loadOptionsAsync = (input) => {

        const query = `/CrmModule/v1.0/db/Premise2/search?terms=${input}&fields=properties.PostalCode,properties.PostalCodeNoSpace&schemas=${process.env.GATSBY_PREMISE_SCHEMA}&page=0&size=100&sort=[{%22properties.DoorNumber%22:{%22order%22:%22asc%22}},{%22properties.SPRN%22:{%22order%22:%22asc%22}}]`

        return API.get(query)
            .then(function (response) {
                return response.data.data.map(category => ({
                    value: JSON.stringify({
                        UDPRN: category.properties.UDPRN,
                        UMPRN: category.properties.UMPRN,
                        SPRN: category.properties.SPRN
                    }),
                    label: category.properties.FullAddress,
                    details: category.properties
                }));
            })
            .catch(function (error) {
                /*
                setAPIErrorMsg({
                    title: 'Address Search Error',
                    message: 'There was a problem accessing our servers. Please try again later.'
                })
                */
            });
    }

    const pageData = getProperty(data, 'strapiContactPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');
    // Modal Success
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [alertVariant, setAlertVariant] = useState('success')
    const closeModal = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey="6LdC-H4aAAAAAN9oY90s98Xr3xr6RKQr1pX7FBxB">
                <PageWrapper footerDark>
                    <Section>
                        <div className="pt-5" />
                        <Container>
                            <Row className="justify-content-center text-center">
                                <Col lg="8">
                                    <div className="banner-content">
                                        <MainTitle>{headingsData.h1}</MainTitle>
                                    </div>
                                    <div>
                                        <Text>
                                            {pageData.subtitle_1}
                                            <br className="d-none d-md-block" />
                                            <div dangerouslySetInnerHTML={{ __html: pageData.subtitle_2 }}></div>
                                        </Text>
                                    </div>
                                    <Text dangerouslySetInnerHTML={{ __html: pageData.enquiry_notice }}></Text>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={12} md={8} className="order-lg-1 pt-5 mt-4">
                                    <div>
                                        <FormStyled>
                                            <Formik
                                                validateOnChange={false}
                                                validateOnBlur={false}
                                                initialValues={{
                                                    firstName: '',
                                                    lastName: '',
                                                    email: '',
                                                    messageText: ''
                                                }}
                                                validationSchema={submitSchema}
                                                onSubmit={async (values, { resetForm, setSubmitting }) => {
                                                    let request = {
                                                        content: `
                                                            Customer: ${values.firstName} ${values.lastName} \n
                                                            Address: ${address ? address.label : ''} \n
                                                            Type of Query: ${queryType ? queryType.label : ''} \n\n
                                                            Message: ${values.messageText}
                                                        `,
                                                        expire_select: "never",
                                                        force_author: values.email,
                                                        is_private: 0,
                                                        object_type: "ticket",
                                                        ownership: "helpdesk",
                                                        responsible_id: 0,
                                                        title: queryType ? queryType.label : 'Netomnia Contact Form',
                                                        properties: {
                                                            first_name: values.firstName,
                                                            last_name: values.lastName,
                                                            address: address ? address.label : '',
                                                        }
                                                    };

                                                    try {
                                                        APINO.post(`/CrmModule/v1.0/ticket/create`, request).then((resp) => {
                                                            // After successful submission, reset the form fields
                                                            resetForm();
                                                            setQueryType(null)
                                                            setAddress(null)
                                                            setSubmitting(false)
                                                            setIsModalOpen(true)
                                                            setAlertVariant('success')
                                                        }).catch(error => {
                                                            alert('Error')
                                                        });
                                                    } catch (e) {
                                                        console.log(e)
                                                    }
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    handleChange,
                                                    setFieldValue,
                                                }) => (
                                                    <Form>

                                                        {/* First name */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type=""
                                                                name="firstName"
                                                                placeholder="First name"
                                                                value={values.firstName}
                                                                onChange={handleChange}
                                                                className={errors.firstName ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.firstName)}
                                                        </Box>

                                                        {/* Last name */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type=""
                                                                name="lastName"
                                                                placeholder="Last name"
                                                                value={values.lastName}
                                                                onChange={handleChange}
                                                                className={errors.lastName ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.lastName)}
                                                        </Box>

                                                        {/* Type of Query */}
                                                        <Box md={4} className="mb-32">
                                                            <Select
                                                                autocomplete="off"
                                                                defaultValue=""
                                                                isClearable
                                                                name="typeOfQuery"
                                                                options={typesOfQuery}
                                                                value={queryType}
                                                                isSearchable={false}
                                                                placeholder="Type of Query"
                                                                className="reactSelect"
                                                                onChange={option => {
                                                                    setQueryType(option)
                                                                }}
                                                            />
                                                            {
                                                                queryType && queryType.value === 'BROADBAND_PROBLEM' &&
                                                                <small style={{ color: 'red' }}>
                                                                    If you are enquiring about your broadband service, you will need to reach out to your ISP directly.
                                                                </small>
                                                            }
                                                        </Box>

                                                        {/* Address */}
                                                        <Box mb={4} className="mb-32">
                                                            <AsyncSelect
                                                                id="reactSelect"
                                                                isClearable
                                                                value={address}
                                                                autoFocus={false}
                                                                isSearchable={true}
                                                                placeholder="Postcode"
                                                                className="reactSelect"
                                                                loadOptions={loadOptionsAsync}
                                                                styles={{
                                                                    option: (provided, state) => ({
                                                                        ...provided,
                                                                        fontSize: '1.2em',
                                                                        ':hover': {
                                                                            backgroundColor: '#f8f8f8'
                                                                        }
                                                                    }),
                                                                    control: (provided) => {
                                                                        return {
                                                                            ...provided,
                                                                            borderRadius: 10,
                                                                            padding: "1rem 1rem",
                                                                            outline: "none",
                                                                            boxShadow: "none",
                                                                            fontSize: '1.2em'
                                                                        };
                                                                    }
                                                                }}
                                                                onChange={option => {
                                                                    setAddress(option)
                                                                }}
                                                            />
                                                        </Box>

                                                        {/* E-mail address */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type="email"
                                                                name="email"
                                                                placeholder="E-mail address"
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                className={errors.email ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.email)}
                                                        </Box>
                                                        
                                                        {/* Message */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type="text"
                                                                as="textarea"
                                                                rows={4}
                                                                name="messageText"
                                                                placeholder="Write your message here"
                                                                value={values.messageText}
                                                                onChange={handleChange}
                                                                className={errors.messageText ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.messageText)}
                                                        </Box>
                                                        {/* Submit button */}
                                                        <div className="pt-4 text-center">
                                                            <Button width="60%" type="submit" borderRadius={10}>
                                                                Send Message
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </FormStyled>

                                        {/*    MODAL */}
                                        <Modal centered show={isModalOpen} onHide={closeModal}>
                                            <Modal.Header>
                                                <Modal.Title>Thank you for contacting us</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className={`alert alert-${alertVariant}`}>
                                                    The team is working on your request and will aim to respond in the next 5 business days.
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={closeModal}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        {/*    MODAL */}

                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center text-center">
                                <Col lg="8">
                                    <h3 className="color-black mt-5">{pageData.title_2}</h3>
                                    <br />
                                    <Text dangerouslySetInnerHTML={{ __html: pageData.text_1 }}></Text>
                                    <br />
                                    <Text dangerouslySetInnerHTML={{ __html: pageData.text_3 }}></Text>
                                    <br />
                                </Col>
                            </Row>
                        </Container>
                    </Section>
                </PageWrapper>
            </GoogleReCaptchaProvider>
        </>
    );
};

export default Contact;
